import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from '@mui/material/Pagination';
import { Scatter, Line } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, PointElement, LinearScale, CategoryScale, TimeScale, LineElement } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter for time scale
import regression from 'regression';
import './App.css';

// Register required elements
ChartJS.register(Tooltip, Legend, PointElement, LinearScale, CategoryScale, TimeScale, LineElement);

function App() {
  const [articles, setArticles] = useState([]);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [guardianPage, setGuardianPage] = useState(1);
  const [nytimesPage, setNytimesPage] = useState(1);
  const [redditPage, setRedditPage] = useState(1);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [growthData, setGrowthData] = useState([]);
  const [projectedData, setProjectedData] = useState([]);

  const ARTICLES_PER_PAGE = 10;
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8082';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articlesRes = await axios.get( `${apiUrl}/articles`);
        const trendingRes = await axios.get(`${apiUrl}/trending`);

        const articlesData = Array.isArray(articlesRes.data) ? articlesRes.data : [];
        const trendingData = Array.isArray(trendingRes.data) ? trendingRes.data : [];

        setArticles(articlesData);
        setFilteredArticles(articlesData);
        setTrendingTopics(trendingData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setArticles([]);
        setFilteredArticles([]);
        setTrendingTopics([]);
      }
    };

    fetchData();

    const fetchGrowthData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/crypto-growth`, {
          params: {
            id: 'bitcoin',
            days: '365',
            interval: 'daily',
          },
        });

        const data = Array.isArray(response.data) ? response.data : [];

        if (data.length === 0) {
          console.error('Empty growth data received');
          setGrowthData([]);
          setProjectedData([]);
          return;
        }

        const parsedGrowthData = data.map(point => ({
          date: new Date(point.date),
          price: point.price,
        }));

        setGrowthData(parsedGrowthData);

        const regressionData = parsedGrowthData.map((point, index) => [index, point.price]);
        const result = regression.linear(regressionData);

        const projectedData = [];
        const lastIndex = regressionData.length - 1;
        for (let i = 1; i <= 30; i++) {
          const x = lastIndex + i;
          const y = result.predict(x)[1];
          projectedData.push({
            date: new Date(Date.now() + i * 24 * 60 * 60 * 1000),
            price: y,
          });
        }

        setProjectedData(projectedData);
      } catch (error) {
        console.error('Error fetching growth data:', error);
        setGrowthData([]);
        setProjectedData([]);
      }
    };

    fetchGrowthData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const updatedArticles = articles.filter(article =>
      article.title.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredArticles(updatedArticles);
    setGuardianPage(1);
    setNytimesPage(1);
    setRedditPage(1);
  };

  const handleTrendingClick = (topic) => {
    setSearchTerm(topic.keyword);
    const updatedArticles = articles.filter(article =>
      article.title.toLowerCase().includes(topic.keyword.toLowerCase())
    );
    setFilteredArticles(updatedArticles);
    setGuardianPage(1);
    setNytimesPage(1);
    setRedditPage(1);
  };

  const handlePageChange = (setPage) => (event, value) => {
    setPage(value);
  };

  const getSentimentColor = (score) => {
    if (score > 0) return `rgba(0, 255, 0, ${Math.min(score / 5, 1)})`;
    if (score < 0) return `rgba(255, 0, 0,${Math.min(Math.abs(score) / 5, 1)})`;
    return 'rgba(255, 255, 0, 1)';
  };

  const paginateArticles = (articles, page) => {
    const startIndex = (page - 1) * ARTICLES_PER_PAGE;
    return articles.slice(startIndex, startIndex + ARTICLES_PER_PAGE);
  };

  return (
    <div className="container">
      <h1 className="text-center">CryptoBoard</h1>
      <section className="search-section">
        <div className="mb-3">
          <label className="form-label">Search Articles:</label>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Enter keywords"
          />
        </div>
      </section>

      <section className="growth-chart-section">
        <h2>Cryptocurrency Growth Chart</h2>
        <Line
          data={{
            labels: [
              ...growthData.map(data => data.date),
              ...projectedData.map(data => data.date)
            ],
            datasets: [
              {
                label: 'Bitcoin Price (USD)',
                data: growthData.map(data => data.price),
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
              },
              {
                label: 'Projected Price (USD)',
                data: [...new Array(growthData.length).fill(null), ...projectedData.map(data => data.price)],
                borderColor: 'rgb(255, 99, 132)',
                borderDash: [5, 5],
                tension: 0.1,
              },
            ],
          }}
          options={{
            scales: {
              x: {
                type: 'time',
                time: { unit: 'month' },
                title: { text: 'Date', display: true },
              },
              y: { title: { text: 'Price (USD)', display: true } },
            },
          }}
        />
      </section>

      <section className="trending-topics">
        <h2>Trending Topics</h2>
        <div className="topics-list">
          {trendingTopics.map(topic => (
            <span
              key={topic.keyword}
              className="trending-topic"
              onClick={() => handleTrendingClick(topic)}
            >
              #{topic.keyword} ({topic.count})
            </span>
          ))}
        </div>
      </section>

      {['guardian', 'nytimes', 'reddit'].map((source, index) => (
        <section key={index} className="articles-section">
          <h2>{`${source.charAt(0).toUpperCase() + source.slice(1)} Articles`}</h2>
          <ul>
            {filteredArticles
              .filter(article => article.source === source)
              .slice(0, ARTICLES_PER_PAGE)
              .map((article, i) => (
                <li key={i} style={{ borderLeftColor: getSentimentColor(article.sentiment || 0) }}>
                  <a href={article.url || article.link} target="_blank" rel="noopener noreferrer">
                    {article.title || "Untitled"}
                  </a>
                </li>
              ))}
          </ul>
          <Pagination
            count={Math.ceil(filteredArticles.filter(a => a.source === source).length / ARTICLES_PER_PAGE)}
            page={source === 'guardian' ? guardianPage : source === 'nytimes' ? nytimesPage : redditPage}
            onChange={handlePageChange(
              source === 'guardian' ? setGuardianPage : source === 'nytimes' ? setNytimesPage : setRedditPage
            )}
            variant="outlined"
            shape="rounded"
          />
        </section>
      ))}
    </div>
  );
}

export default App;